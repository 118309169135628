@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.edit-boot-camp-details {
  margin-bottom: 200px; // NOTE: Custom margin to avoid content being hidden by sticky buttons

  &__header {
    background: light.$background-secondary-default;
    padding: spacing.$l 0 spacing.$m 0;
  }

  &__title {
    color: light.$on-surface-primary-default;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    margin-top: spacing.$m;
    padding: spacing.$m;
    border-radius: corner-radius.$m;
    background: light.$background-secondary-default;

    @media (min-width: 650px) {
      margin-top: spacing.$l;
    }
  }

  &__form {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: spacing.$m;

    &--horizontal {
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        flex: 1;
        min-width: 0;
      }
    }
  }

  &__explanation {
    display: flex;
    align-items: center;
    gap: spacing.$xs;
    color: light.$on-surface-primary-alternate;
  }

  &__visbility-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacing.$xxs;
  }

  &__visbility {
    display: flex;
    align-items: center;
    padding: spacing.$s;
    gap: spacing.$s;
    border-radius: 100px;
    background: light.$surface-secondary-default;
  }
}
